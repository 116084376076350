<template>
  <div
    v-if="isVisible"
    ref="container"
    class="r-pagination-new"
  >
    <div
      v-if="hasControl('prev')"
      class="r-pagination-new__controls"
    >
      <r-button
        simple
        :mini="small"
        :tiny-icon="small"
        class="r-pagination-new__arrow"
        :icon="{
          name: 'double-chevron-down',
          rotate: 90
        }"
        :disabled="firstActive"
        @click="goFirst"
      />
      <r-button
        simple
        :mini="small"
        :tiny-icon="small"
        class="r-pagination-new__arrow"
        :icon="{
          name: 'chevron-down',
          rotate: 90
        }"
        :disabled="firstActive"
        @click="stepTo(-1)"
      />
    </div>

    <div
      v-if="hasControl('pager')"
      class="r-pagination-new__controls"
    >
      <r-button
        v-for="pageNumber in pages"
        :key="pageNumber"
        type="secondary"
        class="r-pagination-new__page-number"
        :mini="small"
        bold
        :active="isActive(pageNumber)"
        :simple="!isActive(pageNumber)"
        @click="onPageChange(pageNumber)"
      >
        {{ pageNumber }}
      </r-button>
    </div>

    <div
      v-if="hasControl('next')"
      class="r-pagination-new__controls"
    >
      <r-button
        simple
        :mini="small"
        :tiny-icon="small"
        class="r-pagination-new__arrow"
        :icon="{
          name: 'chevron-down',
          rotate: -90
        }"
        :disabled="lastActive"
        @click="stepTo(1)"
      />
      <r-button
        simple
        :mini="small"
        :tiny-icon="small"
        class="r-pagination-new__arrow"
        :icon="{
          name: 'double-chevron-down',
          rotate: -90
        }"
        :disabled="lastActive"
        @click="goLast"
      />
    </div>

    <div
      v-if="hasControl('jumper')"
      class="r-pagination-new__controls jumper"
    >
      <r-text
        color-type="secondary"
        no-wrap
      >
        {{ $t('go-to') }}
      </r-text>
      <r-input-number
        class="r-pagination-new__jumper-input"
        :value="currentPage"
        :max="totalPages"
        mini
        no-controls
        @input="onInput"
        @blur="setFromInput"
        @enter="setFromInput"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref, onMounted } from 'vue'

// eslint-disable-next-line no-undef
const emit = defineEmits(['page-change'])

// eslint-disable-next-line no-undef
const props = defineProps({
  page: {
    type: Number,
    default: 1
  },
  count: {
    type: Number,
    default: 0
  },
  pageSize: {
    type: Number,
    default: 50
  },
  pagerCount: {
    type: Number,
    default: 5
  },
  showOnSinglePage: {
    type: Boolean,
    default: false
  },
  small: {
    type: Boolean,
    default: false
  },
  layout: {
    type: String,
    default: 'prev, pager, next'
  }
})

const currentPage = ref(props.page)
let jumperCache = null
const container = ref(null)

const containerWidth = computed(() => {
  return container.value?.getBoundingClientRect().width
})

const autoPagesCount = computed(() => {
  let count = getPagesCount(getLen(currentPage.value))
  const offset = count / 2
  let lastVisiblePage

  if (currentPage.value < offset + 1) {
    lastVisiblePage = count
  } else if (currentPage.value > totalPages.value - offset) {
    lastVisiblePage = totalPages.value
  } else {
    lastVisiblePage = currentPage.value + ~~offset
  }

  if (getLen(lastVisiblePage) - getLen(currentPage.value) > 0) {
    count = getPagesCount(getLen(lastVisiblePage))
  }

  return ~~count
})

const pagesCount = computed(() => {
  return props.small ? autoPagesCount.value : props.pagerCount
})

const totalPages = computed(() => {
  return Math.ceil(props.count / props.pageSize) || 0
})

const startPage = computed(() => {
  const offset = Math.ceil(pagesCount.value / 2)

  if (currentPage.value <= offset) {
    return 1
  }

  const start = currentPage.value - offset + 1

  if (
    currentPage.value === totalPages.value ||
    totalPages.value - start < pagesCount.value
  ) {
    const value = totalPages.value - pagesCount.value + 1

    return value > 0 ? value : 1
  }

  return start
})

const endPage = computed(() => {
  return Math.min(startPage.value + pagesCount.value - 1, totalPages.value)
})

const pages = computed(() => {
  const range = []
  for (let i = startPage.value; i <= endPage.value; i += 1) {
    range.push(i)
  }

  return range
})

const firstActive = computed(() => {
  return currentPage.value === 1
})

const lastActive = computed(() => {
  return currentPage.value === totalPages.value
})

const isVisible = computed(() => {
  if (props.showOnSinglePage) return true

  return totalPages.value > 1
})

const getLen = page => {
  return (String(page).length - 1) * 0.9 + 1
}

const hasControl = control => {
  return props.layout?.includes(control)
}

const isActive = page => {
  return currentPage.value === page
}

const onPageChange = page => {
  if (isActive(page)) return

  currentPage.value = page
}

const goFirst = () => {
  currentPage.value = 1
}

const goLast = () => {
  currentPage.value = totalPages.value
}

const stepTo = val => {
  currentPage.value += val
}

const goTo = val => {
  currentPage.value = val
}

const onInput = val => {
  jumperCache = val
}

const setFromInput = () => {
  if (!jumperCache) return

  if (jumperCache > totalPages.value) {
    currentPage.value = totalPages.value
  } else if (parseInt(jumperCache) < 1) {
    currentPage.value = totalPages.value
  } else {
    currentPage.value = ~~jumperCache
  }
}

const getContainerWidth = () => {
  return container.value.getBoundingClientRect().width
}

const getPagesCount = pageLen => {
  const FS = 16 //font-size
  const containerPadding = 0.25
  const containerGap = 0.25
  const btnGap = 0.1
  const arrowWidth = 1.25
  const initBtnsQty = 4 // OR 2
  const symbolWidth = 8
  const btnPadding = 0.5

  const slotWidth = (btnGap + 2 * btnPadding) * FS + pageLen * symbolWidth

  const pageSlotPlacesQty =
    FS *
    (containerPadding * 2 +
      btnGap * (initBtnsQty / 2) +
      arrowWidth * initBtnsQty +
      2 * containerGap)

  const count = (containerWidth.value - pageSlotPlacesQty) / slotWidth

  return ~~count
}

watch(currentPage, (val, prev) => {
  emit('page-change', val)
})

watch(
  () => props.page,
  val => {
    currentPage.value = val
  }
)
</script>

<style lang="scss">
.r-pagination-new {
  display: grid;
  align-self: end;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.25rem;
  padding: 0.25rem;
  width: 100%;

  &__jumper-input {
    width: 100%;
    padding: 0 0.05rem !important;
    max-width: 48px;
    min-width: 36px !important;

    & input {
      padding: 0 !important;
    }
  }

  &__arrow {
    padding: 0 0.1rem !important;
    max-width: 1.25rem !important;
  }

  &__page-number {
    padding: 0.5rem !important;

    &.r-button__text {
      display: inline-flex;
      width: auto !important;
    }
  }

  &__controls {
    display: grid;
    grid-gap: 0.1rem;
    grid-auto-flow: column;
    align-items: center;

    &.jumper {
      grid-gap: 0.5rem;
      padding: 0 0.5rem;
    }
  }
}
</style>
