export const structure = {
  map: {},
  fields: {
    parkings: null,
    parkomats: null,
    terminals: null,
    taxi: null
  },
  city: null,
  filters: {},
  objectCard: null,
  objectEditor: null,
  layerNames: {},
  activeLayers: [],
  isSelectDisabled: false,
  hasUnsavedData: false
}

export const nonClearableFields = ['city', 'map', 'fields']
