import i18n from '@/extensions/i18n'
import $store from 'STORE'
import vue from 'vue'
import $router from '@/router'
import { serverToFrontModuleRelations } from '@/config/system-settings'

export const checkUnsavedData = async ({ module } = {}) => {
  let moduleName

  if (module) {
    moduleName = module
  } else {
    const path = $router.history.current.path
    const route = path.match(/^\/[^/]*\/[^/]*\/([^/?]*)/)?.[1].replace('-', '_')

    moduleName = serverToFrontModuleRelations[route] || route
  }

  const mutationName = $store.state.storeRelations[moduleName]

  if (!mutationName || !$store.state[moduleName]) return

  const hasUnsavedData = $store.state[moduleName].hasUnsavedData

  if (
    hasUnsavedData &&
    !(await vue.$dialog({
      title: i18n.t('unsaved_data_will_be_lost') + '!',
      message: i18n.t('do_you_want_to_continue')
    }))
  ) {
    return true
  }

  $store.commit(mutationName, ['hasUnsavedData', false])

  return false
}
