import { POST, GET, setAxiosToken } from 'API'
import $store from 'STORE'
import { setCookie } from 'HELPERS'
import eventBus, { eventNames } from 'EVENT_BUS'

const { UPDATE_MAP_CONFIG } = eventNames

const storeFields = { mapConfig: 'map', regionConfig: 'city' }

export default class UserController {
  constructor() {
    this.saveConfigInLocalStorage = this.saveConfigInLocalStorage.bind(this)
    this.saveMapConfigInLocalStorage =
      this.saveMapConfigInLocalStorage.bind(this)
  }

  async init() {
    await this.loadUser()
    await this.defineAvailableCities(true)

    this.loadMapConfig()

    eventBus.on(UPDATE_MAP_CONFIG, this.saveMapConfigInLocalStorage)
  }

  destroy() {
    eventBus.off(UPDATE_MAP_CONFIG, this.saveMapConfigInLocalStorage)
  }

  async login({ login, password }) {
    const formData = new FormData()

    formData.append('login', login)
    formData.append('password', password)

    const { data } = await POST('mrir/login', formData)

    this.setToken(data)
  }

  setToken({ token }) {
    if (!token) return

    setCookie('ritmToken', token, 1)
    $store.commit('USER_SET', ['token', token])
    setAxiosToken(token)
  }

  async loadUser() {
    try {
      const [{ data: user }, { data: modules }] = await Promise.all([
        GET('mrir/users/current', null, { maxAge: 86400 }),
        GET(`mrir/modules`, null, { maxAge: 86400 })
      ])

      $store.commit('MRIR_SET', ['modules', modules])

      const modulePerms = new Map()

      modules?.forEach(m => {
        if (m) modulePerms.set(m.name, m)
      })

      if (user.profile) delete user.profile

      $store.commit('USER_SET', ['user', user])
      $store.commit('USER_SET', ['permissions.modules', modulePerms])
    } catch (e) {
      throw e
    }
  }

  // -----*-----*-----*-----*-----*-----*-----*----- //

  loadMapConfig() {
    const { storeRelations } = $store.state

    for (const key in storeFields) {
      const config = localStorage.getItem(key)
      const resultConfig = config ? JSON.parse(config) : {}

      if (Object.keys(resultConfig).length) {
        for (const module in resultConfig) {
          if (resultConfig[module]) {
            $store.commit(storeRelations[module], [
              storeFields[key],
              resultConfig[module]
            ])
          }
        }
      }
    }
  }

  async defineAvailableCities(withCache) {
    try {
      const { data } = await GET(
        'mrir/regions',
        null,
        !withCache && { maxAge: 0 }
      )

      $store.commit('SYSTEM_SET', [
        'availableCities',
        data.toSorted((a, b) => a.id - b.id) || []
      ])
    } catch (e) {
      throw e
    }
  }

  prepareMapConfig(name) {
    const modules = Object.keys($store.state.storeRelations)

    return modules.reduce((a, m) => {
      const moduleMap = $store.state[m]?.[name]

      if (moduleMap) {
        a[m] = moduleMap
      }

      return a
    }, {})
  }

  saveMapConfigInLocalStorage() {
    for (const key in storeFields) {
      const config = this.prepareMapConfig(storeFields[key])

      if (Object.keys(config).length) {
        localStorage.setItem(key, JSON.stringify(config))
      }
    }
  }

  async saveConfigInLocalStorage(path) {
    if (document.visibilityState === 'visible') return

    this.saveMapConfigInLocalStorage()

    // const name = pathToServerModuleRelations[path]
    // config.system = { timestamp: Date.now() }

    // TODO: temporary off
    // if (e?.type === 'visibilitychange') {
    //   const bc = new BroadcastChannel('localforage')
    //   const oldValue = await localforage.getItem('config')
    //   bc.postMessage({ newValue: config, oldValue })
    // }
  }
}
