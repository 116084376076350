<template>
  <div>
    <portal to="main-portal">
      <r-button
        v-if="aLotOfNotifications"
        class="notifications__dismiss-button"
        type="warning"
        icon="close-delete"
        @click="dismissAll"
      >
        {{ i18n.t('dismiss-all-notifications') }}
      </r-button>
      <transition-group
        class="notifications"
        :class="{ offset: aLotOfNotifications }"
        name="list-complete"
        tag="div"
      >
        <notification
          v-for="item in list"
          :id="item.id"
          :key="item.id"
          class="list-complete-item"
          :message="item.message"
          :title="item.title"
          :icon="item.icon"
          :link="item.link"
          :duration="item.duration"
          :type="validType(item.type)"
          @delete="handleDelete"
        />
      </transition-group>
    </portal>
  </div>
</template>

<script>
import localforage from 'LF'
import vue from 'vue'
import { generateId } from 'HELPERS'
import VueI18n from 'vue-i18n'
import locale from './locale.json'

export default {
  components: {
    Notification: () => import('./notification')
  },
  data() {
    return {
      list: [],
      parent: null,
      i18n: null
    }
  },
  computed: {
    aLotOfNotifications() {
      return this.list.length > 3
    }
  },
  async created() {
    const systemConfig = await localforage.getItem('systemConfig')
    this.i18n = new VueI18n({
      locale:
        systemConfig?.locale ||
        (navigator.language || navigator.languages[0])?.startsWith('ru')
          ? 'ru'
          : 'en',
      silentTranslationWarn: true,
      messages: locale
    })
  },
  methods: {
    validType(type) {
      const regularTypes = [
        'danger',
        'warning',
        'error',
        'primary',
        'info',
        'success'
      ]

      return regularTypes.includes(type) ? type : null
    },
    dismissAll() {
      this.list = []
    },
    addNotification({ id, type, title, message, duration, icon, link }) {
      const index = this.list?.findIndex(n => id && n.msgId === id)

      const notification = {
        title,
        message: message || '',
        icon: icon || '',
        duration,
        type,
        link,
        msgId: id,
        id: index > -1 ? this.list[index]?.id : generateId()
      }

      if (index > -1) {
        vue.set(this.list, index, notification)
      } else {
        this.list.unshift(notification)
      }
    },
    handleDelete(id) {
      this.list = this.list.filter(item => item.id !== id)
    }
  }
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  top: 3.5rem;
  right: 0.5rem;
  z-index: 4000;
  display: grid;
  overflow-y: auto;
  justify-content: end;
  overflow-x: hidden;
  width: 330px;
  align-items: start;
  max-height: calc(100vh - 4rem);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.offset {
    top: 6.5rem;
  }

  &__dismiss-button {
    position: fixed;
    top: 3.5rem;
    right: 0.5rem;
    z-index: 3000;
  }
}

.list-complete-item {
  transition: all 0.33s;
}

.list-complete-item {
  transition: all 0.33s ease;
}

.list-complete-enter-active {
  transform: translateX(0);
  opacity: 1;
}

.list-complete-enter {
  transform: translateX(100%);
  opacity: 0;
}

.list-complete-enter-to {
  animation: enter 0.33s ease;
  opacity: 1;
}

.list-complete-leave-to {
  opacity: 0;
}
.list-complete-leave {
  opacity: 0;
}

.list-complete-leave-active {
  animation: leave 0.33s ease;
  opacity: 0.33;
}

@keyframes leave {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes enter {
  0% {
    transform: translateX(100%);
  }
  80% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
}
</style>
