<template>
  <portal to="main-portal">
    <transition
      name="r-modal"
      @before-enter="beforeEnter"
    >
      <div
        v-if="show"
        class="r-modal__wrapper"
        :style="{ zIndex }"
        @mousedown.stop="handleMouseDown"
        @mouseup.stop="handleMouseUp"
      >
        <!-- @mousedown.stop="closeModalByOverlay" -->
        <div
          class="r-modal__overlay"
          :style="{ padding: `${marginTop}px 0` }"
        >
          <div
            ref="content"
            class="r-modal__content"
            :class="{ 'keep-this-class-opened': ddParent }"
            :style="{ 'max-width': `${props.maxWidth}px` }"
            role="dialog"
          >
            <div
              class="r-modal__title"
              :class="{ back: backBtn }"
            >
              <r-button
                v-if="backBtn"
                icon="back"
                mini
                simple
                :title="$t(backTitle)"
                @click="$emit('back')"
              />
              <r-title color-type="subhead">
                {{ title }}
              </r-title>
              <r-button
                icon="close-delete"
                mini
                simple
                @click="closeModal"
              />
            </div>

            <div class="r-modal__body-wrapper">
              <div
                v-if="$slots.header"
                class="r-modal__header"
              >
                <slot name="header" />
              </div>

              <div
                class="r-modal__body"
                :class="[{ rounded }, bodyClass]"
              >
                <slot />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script setup>
import i18n from '@/extensions/i18n'

import { ref, onBeforeUnmount, nextTick, watch, computed } from 'vue'
import vue from 'vue'

// eslint-disable-next-line no-undef
const $emit = defineEmits(['close', 'back'])

// eslint-disable-next-line no-undef
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  bodyClass: {
    type: String,
    default: ''
  },
  rounded: {
    type: Boolean,
    default: false
  },
  ddParent: {
    type: Boolean,
    default: false
  },
  askBeforeClose: {
    type: Boolean,
    default: false
  },
  closeByOverlay: {
    type: Boolean,
    default: true
  },
  closeByEsc: {
    type: Boolean,
    default: true
  },
  maxWidth: {
    type: Number,
    default: 640
  },
  marginTop: {
    type: Number,
    default: 64
  },
  zIndex: {
    type: Number,
    default: 1000
  },
  backBtn: {
    type: Boolean,
    default: false
  },
  backTitle: {
    type: String,
    default: 'back'
  }
})

const show = ref(props.show)
const content = ref(null)
const isOverlayMouseDown = ref(false)

const closeModal = async () => {
  if (props.askBeforeClose) {
    if (
      await vue.$dialog({
        title: i18n.t('close-this-window'),
        message: i18n.t('unsaved-changes-will-be-lost')
      })
    ) {
      $emit('close')
    }
  } else $emit('close')
}

const handleMouseDown = e => {
  if (
    props.closeByOverlay &&
    !isOverlayMouseDown.value &&
    e.composedPath()[0].className === 'r-modal__overlay'
  ) {
    isOverlayMouseDown.value = true
  }
}

const handleMouseUp = e => {
  if (
    props.closeByOverlay &&
    isOverlayMouseDown.value &&
    e.composedPath()?.[0]?.className === 'r-modal__overlay'
  ) {
    closeModal()
  }

  isOverlayMouseDown.value = false
}

const escCloseModal = e => {
  e.stopPropagation()
  e.preventDefault()

  if (props.show && e.code === 'Escape' && props.closeByEsc) {
    closeModal()
  }
}

const beforeEnter = () => {
  if ('click' in content.value) {
    nextTick(() => content.value?.click())
  }
}

watch(
  () => props.show,
  isShow => {
    show.value = isShow

    if (isShow && props.closeByEsc) {
      window.addEventListener('keyup', escCloseModal, true)
    } else {
      window.removeEventListener('keyup', escCloseModal, true)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  window.removeEventListener('keyup', escCloseModal, true)
})
</script>

<style lang="scss" scoped>
.r-modal {
  &__wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-overlay;
    opacity: 1;
    transition: all 0.15s ease-in-out;
    z-index: 1000;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__content {
    width: 100%;
    max-height: 100%;
    border-radius: $border-radius;
    background-color: $modal-bg;
    display: grid;
    grid-gap: 0.5rem;
    position: relative;
    box-shadow: $box-shadow-xl;
    align-content: start;
    grid-template-rows: auto 1fr;
  }

  &__title {
    @include grid-row;
    @include border-bottom;
    height: 60px;
    grid-template-columns: 1fr auto;
    padding: 0.5rem 1rem;

    &.back {
      grid-template-columns: auto 1fr auto;
    }

    .r-title {
      @include line-clamp(1);
    }
  }

  &__body-wrapper {
    display: grid;
    grid-gap: 0.5rem;
    overflow: hidden;
    padding: 0 1rem 1rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__body {
    overflow: auto;

    &.rounded {
      border-radius: $border-radius;
    }
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.15s ease-in-out;
    position: absolute;
  }
}
</style>
