<template>
  <div
    class="r-color-picker"
    :class="{ stretch }"
  >
    <r-dropdown
      :menu-max-height="314"
      :menu-max-width="280"
      :show="showDD"
      @close="showDD = true"
    >
      <color-item
        :mini="mini"
        :stretch="stretch"
        :color="colorValue"
      />
      <div
        slot="dropdown-menu"
        class="r-color-picker__content"
      >
        <color-item
          :color="colorValue"
          :opacity="opacityValue"
          mode="wide"
        />
        <r-text v-if="withOpacity">
          {{ $t('opacity') }}
        </r-text>
        <r-slider
          v-if="withOpacity"
          :min="0"
          :max="100"
          :step="5"
          :value="Math.round(opacityValue * 100)"
          @change="changeOpacity($event)"
        />
        <div class="r-color-picker__color-list">
          <color-item
            v-for="clr in palette"
            :key="clr"
            :color="clr"
            :active="clr === colorValue"
            @click="selectColor(clr)"
          />
        </div>
        <r-divider />
        <div class="r-color-picker__color-list">
          <color-item
            v-for="(item, ndx) in userColors"
            :key="item.id"
            :color="item.value"
            :active="item.value === colorValue"
            :mode="removeMode ? 'remove' : ''"
            @click="selectColor(item.value)"
            @remove="removeUserColor(ndx)"
          />
          <label
            v-show="userColors.length < 6"
            for="color-input"
            class="r-color-picker__label"
          >
            <color-item
              v-show="!removeMode"
              mode="add"
              @add="addUserColor"
            />
          </label>
        </div>
        <r-button
          v-if="Object.keys(userColors).length > 0 || removeMode"
          :type="removeMode ? 'primary' : 'default'"
          :icon="removeMode ? '' : 'trash'"
          bolder
          @click="removeMode = !removeMode"
        >
          {{ $t(removeMode ? 'done' : 'delete') }}
        </r-button>
        <input
          id="color-input"
          ref="colorInput"
          type="color"
          class="r-color-picker__input"
          @input="changeColor($event.target.value)"
        />
      </div>
    </r-dropdown>
  </div>
</template>

<script>
import { getTheme, debounce, cloneDeep, throttle } from 'HELPERS'
import COLORS from 'COLORS'
import localforage from 'LF'

const palette = [
  '#ffffff',
  '#cccccc',
  '#7F7F7F',
  '#1A1A1C',
  '#F3452A',
  '#F37843',
  '#F8C80E',
  '#FCF640',
  '#91CF51',
  '#14CCD4',
  '#2E59F2',
  '#9610AF'
]

export default {
  components: {
    colorItem: () => import('./r-color-picker/color-item')
  },
  props: {
    color: {
      type: String,
      default: null
    },
    colorOpacity: {
      type: Number,
      default: 1
    },
    withOpacity: {
      type: Boolean,
      default: false
    },
    mini: {
      type: Boolean,
      default: true
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      colorValue: this.color,
      opacityValue: this.colorOpacity,
      lsColors: [],
      removeMode: false,
      lastColor: COLORS[getTheme()].accentPrimary,
      showDD: true,
      palette
    }
  },
  computed: {
    userColors: {
      get() {
        return this.lsColors
      },
      async set(val) {
        this.lsColors = val
        await localforage.setItem('userColors', JSON.stringify(val))
      }
    }
  },
  watch: {
    colorValue() {
      this.updateInput()
    },
    color(val) {
      this.colorValue = val
      this.opacityValue = this.colorOpacity
    },
    opacityValue() {
      this.updateInput()
    },
    colorOpacity(val) {
      this.opacityValue = val
      this.colorValue = this.color
    }
  },
  async created() {
    const colors = await localforage.getItem('userColors')
    this.lsColors = JSON.parse(colors) || []
  },
  methods: {
    selectColor(color) {
      this.colorValue = color
      this.showDD = false
    },
    updateInput: debounce(function () {
      this.$emit('change', {
        color: this.colorValue,
        opacity: this.opacityValue
      })
    }, 64),
    changeOpacity(val) {
      const opacity = val / 100

      if (opacity < 0) this.opacityValue = 0
      else if (opacity > 1) this.opacityValue = 1
      else this.opacityValue = opacity
    },
    changeColor: throttle(function (color) {
      if (!color) return

      const operated = cloneDeep(this.userColors)
      const currentColorId = operated[operated.length - 1].id
      const currentColor = operated.find(item => item.id === currentColorId)

      currentColor.value = this.lastColor = color

      this.userColors = operated
      this.colorValue = color
    }, 128),
    addUserColor() {
      const operated = cloneDeep(this.userColors)

      this.$refs.colorInput.value = this.lastColor

      operated.push({
        id: (+new Date()).toString(16),
        value: this.lastColor
      })

      this.userColors = operated
    },
    removeUserColor(index) {
      const operatedArray = cloneDeep(this.userColors)
      operatedArray.splice(index, 1)
      this.userColors = operatedArray

      if (!Object.keys(this.userColors).length) {
        this.removeMode = false
      }
    }
  }
}
</script>

<style lang="scss">
.r-color-picker {
  width: 100%;
  display: grid;
  grid-gap: 0.25rem 0.5rem;
  align-items: center;

  &__content {
    padding: 0.5rem;
    @include grid-column;
  }

  &__input {
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__color-list {
    @include grid-column;
    grid-template-columns: repeat(6, 1fr);
  }

  &__default-btn {
    justify-self: start;
    padding-left: 0;
  }
}

.stretch {
  .r-dropdown,
  .r-dropdown__dropdown {
    width: unset !important;
  }
}
</style>
