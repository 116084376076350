export default (el, binding, vnode) => {
  if (!binding.oldValue && binding.value) {
    const vm = vnode.context
    const callback = binding.value

    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event)
      }
    }

    document.body.addEventListener('click', el.clickOutsideEvent)
  } else if (binding.oldValue && !binding.value) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
