import vue from 'vue'
import { structure, nonClearableFields } from './structure'
import { cloneDeep } from 'HELPERS'

export default {
  state: {
    ...cloneDeep(structure)
  },
  getters: {
    getTsoddFieldsById: state => id => state.fields[id],
    getTsoddSources: state => state.listSource,
    getTsoddCardId: state => state.cardId
  },
  mutations: {
    TSODD_SECONDARY_STORE_RESET(state) {
      for (const field in structure) {
        if (nonClearableFields.includes(field)) continue
        state[field] = cloneDeep(structure[field])
      }
    },
    TSODD_SECONDARY_SET(state, [field, value]) {
      if (field.includes('.')) {
        const [parent, child] = field.split('.')
        vue.set(state[parent], `${child}`, value)
      } else {
        state[field] = value
      }
    }
  }
}
