import { TRAFFIC_LIGHTS } from './consts'

export const structure = {
  city: null,
  models: null,
  filters: {},
  directionsTips: false,
  endpoint: 'asudd_v2',
  comp: TRAFFIC_LIGHTS,
  map: {},
  editMode: false,
  field: {
    type: 1,
    mode: 2
  },

  selectedTurns: [],

  forwardLights: [],
  backwardLights: [],

  cloneForwardLights: [],
  cloneBackwardLights: [],

  forwardLightGaps: [],
  backwardLightGaps: [],

  cardFields: [],
  csd: [],
  loadingTreeNodeId: null,
  extraLayers: {
    graph: false
  },
  fields: {
    asudd_zpi: {
      angle: {
        alias: {
          ru: 'Угол',
          en: 'Angle'
        },
        type: 'string'
      }
    },
    asudd_pt: {
      grz: {
        alias: {
          ru: 'ГРЗ',
          en: 'GRZ'
        },
        type: 'string'
      },
      type: {
        alias: {
          ru: 'Тип ТС',
          en: 'Vehicle type'
        },
        type: 'string'
      },
      gps_time: {
        alias: {
          ru: 'GPS Время',
          en: 'GPS time'
        },
        type: 'datetime'
      }
    }
  }
}

export const nonClearableFields = ['city', 'map']
