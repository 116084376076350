//getLayerTypeByGeomType
export const additionalLayersByType = {
  common: ['caption'],
  circle: ['heatmap'],
  fill: ['stroke', 'fill_extrusion'],
  line: [
    'arrows',
    'epure',
    'epure_extrusion',
    'epure_labels',
    'epure_directions'
  ],

  links: ['side_arrows', 'lanes_add'],
  connectors: ['side_arrows'],
  zones: ['centroid'],
  main_zones: ['centroid'],
  main_nodes: ['centroid'],
  stops: ['stroke', 'polygon'],
  stop_areas: ['stroke', 'polygon']
}

export const visLayersByType = {
  circle: ['heatmap'],
  fill: ['fill_extrusion'],
  line: ['epure', 'epure_extrusion', 'epure_labels', 'epure_directions']
}

export const clusterLayerNames = ['clusters', 'clusters_count']
