export const structure = {
  map: {},
  tree: [],
  fields: {},
  isSelectDisabled: false,
  layerNames: {},
  layerTypes: {},
  activeLayers: [],
  selectedModelId: null,
  fullDataSetLoaded: {},
  objectsCount: {},
  // pt
  activeTsysCode: null,
  activeLineRouteItems: [],
  ptActiveLayers: [],
  activeRoutes: {},
  lanesEditorObjects: []
}

export const nonClearableFields = ['layerNames', 'map', 'activeLayers']
