import { DITS, INFO_TABLES, ZPIS } from '../config/consts'

export const structure = {
  city: null,
  map: {},
  editorCard: null,
  objectNavHistory: [],
  loadingTreeNodeId: null,
  layerNames: {},
  tables: {},
  filters: {
    [INFO_TABLES]: [],
    [DITS]: [],
    [ZPIS]: []
  },
  fields: {},
  hasUnsavedData: false,
  selectedObjects: [],
  isEditMode: false
}

export const nonClearableFields = ['city', 'map']
