<template>
  <r-block :no-padding="noPadding">
    <r-block
      col
      no-padding
      space-between
    >
      <r-button
        mini
        simple
        :disabled="yearMin - 1 < MIN_AVAILABLE_YEAR"
        :icon="{ name: 'chevron-right', rotate: 180 }"
        @click="updateYearList('prev')"
      />
      <r-text color-type="primary">
        {{ `${yearMin} — ${yearMax}` }}
      </r-text>
      <r-button
        mini
        simple
        :disabled="yearMax + 1 > MAX_AVAILABLE_YEAR"
        icon="chevron-right"
        @click="updateYearList('next')"
      />
    </r-block>

    <r-divider />

    <div class="r-year-picker__body">
      <r-button
        v-for="year in yearList"
        :key="year"
        :disabled="yearDisabled(year)"
        :active="year === value || year === value?.year"
        simple
        @click="yearClickHandler(year)"
      >
        {{ year }}
      </r-button>
    </div>
  </r-block>
</template>

<script setup>
import { sendNotify } from 'NOTIFICATION'
import { ref, onBeforeMount, computed } from 'vue'

import i18n from '@/extensions/i18n'

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: [Number, Object],
    required: true
  },
  yearDisabled: {
    type: Function,
    default: () => false
  },
  noPadding: {
    type: Boolean,
    default: true
  }
})

const MIN_AVAILABLE_YEAR = 1970
const MAX_AVAILABLE_YEAR = 2099

// eslint-disable-next-line no-undef
const $emit = defineEmits(['change'])

const yearMin = ref(null)
const yearList = ref(null)
const currentValue = ref(null)

const yearMax = computed(() => yearMin.value + 9)

const updateYearList = dir => {
  const year = props.value.year ?? props.value

  if (year < MIN_AVAILABLE_YEAR) {
    console.warn('На входе число, которые меньше возможного')
    sendNotify({ type: 'error', message: i18n.t('check_input') })

    yearMin.value = MIN_AVAILABLE_YEAR
  } else if (year > MAX_AVAILABLE_YEAR) {
    console.warn('На входе число, которые больше возможного')
    sendNotify({ type: 'error', message: i18n.t('check_input') })

    yearMin.value = MAX_AVAILABLE_YEAR
  } else {
    if (dir === 'prev') yearMin.value -= 10
    else if (dir === 'next') yearMin.value += 10
    else yearMin.value = Math.floor(year / 10) * 10
  }

  const decade = []

  for (let i = yearMin.value; i <= yearMax.value; i++) {
    decade.push(i)
  }

  yearList.value = decade
}

const yearClickHandler = year => {
  currentValue.value = year
  $emit('change', year)
}

onBeforeMount(() => {
  updateYearList()
})
</script>

<style lang="scss" scoped>
.r-year-picker {
  &__body {
    @include grid-row;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: row;
  }
}
</style>
